import { Link } from '@app/_components/link';
import { css } from '@styled-system/css';
import { HStack, Paragraph } from '@styled-system/jsx';

type Props = {
  icon: string;
  title: string;
  url: string;
};

export const AuthButton = ({ icon, title, url }: Props) => {
  return (
    <Link href={url}>
      <HStack
        width={{ base: '340px', lgDown: '240px' }}
        alignContent="center"
        justifyContent="center"
        fontSize="size_14"
        fontWeight="600"
        lineHeight="21px"
        backgroundColor="white"
        padding="12px 16px"
        border="1px solid #D7D8DA"
        borderRadius="8px"
      >
        <img
          height="24px"
          src={icon}
          alt={title}
          className={css({ height: '24px', maxHeight: '24px' })}
        />
        <Paragraph>{title}</Paragraph>
      </HStack>
    </Link>
  );
};
