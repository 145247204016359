import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { useCallback, useMemo, useState } from 'react';
import { useInputState } from '~/common/hooks/use-input-state';
import { validateEmail } from '~/utils/form';
import { routes } from '~/utils/routes';
import type { EmailFieldTarget } from '@app/auth/_containers/email-field-container';

export const useLocalHooks = (fieldTarget: EmailFieldTarget) => {
  const email = useInputState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();
  const searchParams = useSearchParams();
  const from = useMemo(() => {
    const encodedFrom = searchParams?.get('from');
    return encodedFrom ? decodeURIComponent(encodedFrom) : undefined;
  }, [searchParams]);

  const onChangeEmail = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const { value } = e.target;
      email.setValid(validateEmail(value));
      email.setData(value);
    },
    [email]
  );

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      email.updateShowError();
      if (email.isValid) {
        setIsSubmitting(true);
        const encodedEmail = Buffer.from(email.data).toString('base64');
        if (fieldTarget === 'login') {
          router.push(
            routes.email_login.url(from, {
              e: encodedEmail,
            })
          );
        } else {
          router.push(routes.lp_email_registration.url(encodedEmail, from));
        }
      }
    },
    [email, fieldTarget, from, router]
  );

  return {
    onChangeEmail,
    handleSubmit,
    email,
    isSubmitting,
  };
};
