import React, { useState } from 'react';

export interface UseInputStateMethods {
  data: string;
  setData: React.Dispatch<React.SetStateAction<string>>;
  isValid: boolean;
  setValid: React.Dispatch<React.SetStateAction<boolean>>;
  isShowError: boolean;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  isFilled: () => boolean;
  updateShowError: () => void;
}

export const useInputState = (
  initialValue: string,
  validateFunction?: (v: string) => boolean
): UseInputStateMethods => {
  const [data, setData] = useState(initialValue);
  const [isValid, setValid] = useState(
    validateFunction ? validateFunction(initialValue) : false
  );
  const [isShowError, setShowError] = useState(false);

  const isFilled = (): boolean => data.trim().length > 0;
  const updateShowError = (): void => setShowError(!isValid);

  return {
    data,
    setData,
    isValid,
    setValid,
    isShowError,
    setShowError,
    updateShowError,
    isFilled,
  };
};
