import { cva, type RecipeVariantProps } from '@styled-system/css';
import { styled } from '@styled-system/jsx';

const inputStyle = cva({
  base: {
    width: '100%',
    backgroundColor: 'white',
    padding: '12px 16px',
    border: '1px solid token(colors.accent.100)',
    borderRadius: '8px',
    _focus: {
      outline: 0,
      border: '1px solid token(color.tint)',
      boxSizing: 'border-box',
      boxShadow: '0 0 0 4px rgba(47, 128, 237, 0.5)',
    },
    _placeholder: {
      color: 'accent_500',
    },
  },
  variants: {
    status: {
      error: {
        border: '1px solid token(colors.red)',
      },
    },
  },
});

const inputErrorStyle = cva({
  base: {
    fontSize: 'size_12',
    color: 'red',
    fontWeight: 'bold',
    textAlign: 'left',
    width: '100%',
  },
});

export type InputVariants = RecipeVariantProps<typeof inputStyle>;
export const Input = styled('input', inputStyle);
export const TextArea = styled('textarea', inputStyle);

export const InputError = styled('div', inputErrorStyle);
