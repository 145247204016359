'use client';
import { Loading } from '~/app/_components/loading';
import { Button } from '@app/_components/button';
import { Input } from '@app/_components/input';
import { styled, HStack, VStack } from '@styled-system/jsx';
import { useLocalHooks } from './_hooks/use-local-hooks';
export type EmailFieldTarget = 'login' | 'register';
type Props = {
  fieldTarget: EmailFieldTarget;
};

export const EmailFieldContainer = ({ fieldTarget }: Props) => {
  const { email, onChangeEmail, handleSubmit, isSubmitting } =
    useLocalHooks(fieldTarget);

  return (
    <form role="presentation" autoComplete="off" onSubmit={handleSubmit}>
      <VStack
        width="100%"
        maxWidth="360px"
        justifyContent="left"
        alignItems="left"
        gap="4px"
        margin="auto"
      >
        <styled.label
          color="content_secondary"
          fontSize="size_12"
          textAlign="left"
        >
          メールアドレス
        </styled.label>
        <HStack position="relative" width="100%">
          <Input
            id="email"
            type="email"
            placeholder="email@example.com"
            autoComplete="email"
            data-testid="email"
            value={email.data}
            onChange={onChangeEmail}
            status={email.isShowError ? 'error' : undefined}
          />
          <Button
            data-testid="email-submit"
            borderRadius="5px"
            width="68px"
            height="38px"
            position="absolute"
            right="4px"
            disabled={!email.isValid}
          >
            次へ
          </Button>
          {isSubmitting && <Loading position="absolute" small right="-20px" />}
        </HStack>
      </VStack>
    </form>
  );
};
